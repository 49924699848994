import React, {useState, useEffect} from "react"
import Layout from "../components/layout"


import Colorlist from "../components/colorlist"
import Productlist from "../components/paintingtools/productlist"

import menusearch from "../images/menu-search.png"


import * as styles from "../styles/pages/search.module.css"


const requestJSON = require("../../lib/requestJSON");

const MAXRESULT = 60; // Harcoded

const SearchPage = ({location}) => {
	const [searchtext, setSearchtext] = useState("");
	const [searchinput, setSearchinput] = useState("");
	const [productlist, setProductlist] = useState([]);
	const [colorlist, setColorlist] = useState([]);
	const [isloading, setLoading] = useState(true);


	// This runs when the page is loaded.
	useEffect(() => {
		try {
			var found = false;
			var parameters = location.search;
			if (parameters.length > 0) {
				if (parameters.substring(0,1)=== "?") {
					var paramlist = parameters.substring(1).split("&");
					var paramidx = 0;
					var parampair = [];
					while (paramidx < paramlist.length) {
						parampair = paramlist[paramidx].split("=");
						if (parampair[0] === "q") {
							var tmptext = decodeURIComponent(parampair[1]);
							var checkdecode = "";
							while (tmptext.indexOf("%")>=0) {
								try {
									checkdecode = decodeURIComponent(tmptext);
									tmptext = checkdecode;
								} catch(e) {
									break;
								}
							}
							searchStart(null, tmptext);
							setSearchinput(tmptext);
							found = true;
							break;
						}
						paramidx++;
					}
				}
			}
			if (found === false) {
				setLoading(false);
			}

		} catch (e) {
			setLoading(false);
		}
	}, [location]);


	const searchStart = (e, key) => {
		if (e) {
			e.preventDefault();
		}
		setLoading(true);
		const searchurlbase = "https://search.boysencdn.com/search";
		const payload = {
			"data":{
				"query": {
					"searchtext": key
				}
			}
		};
		setSearchtext(key);
		requestJSON.send(searchurlbase+"/product", "POST", payload).then(productresponse => {
			setProductlist(productresponse);
			requestJSON.send(searchurlbase+"/color", "POST", payload).then(colorresponse => {
				setColorlist(colorresponse);
				setLoading(false);
			});
		});
	}

	const handleSearchChange = (e) => {
		setSearchinput(e.target.value.toLowerCase());
	};


	return (
		<Layout privatePage={true} activePath='search' pageTitle="Search Results" pageDescription="Search Results">
			<div className={"text-centered "+styles.searchbarholder}>
				<div className={styles.searchbar}>
					<button title={"Search"} onClick={(e)=>{searchStart(e, searchinput);}}>
						<img src={menusearch} alt="Search" />
					</button>
					<input className={""} type={"text"} value={searchinput}  onKeyDown={(e) => { if (e.key === "Enter") searchStart(e, searchinput);}} onChange={handleSearchChange} onBlur={handleSearchChange} placeholder={"Search"} />
				</div>
			</div>
			{searchtext.length > 0 &&  <>
				{isloading? <>
					<div className={"text-centered font-size-large "+styles.resulttextonly}>Loading...</div>
				</>:<>
					<h1 className={"text-centered textcolor-primary "}>Search Results for: '{searchtext}'</h1>
					{(productlist.length > 0 || colorlist.length > 0) && <div className={"text-centered textcolor-primary "+styles.resultsummary}>
						{productlist.length > 0 && <div>
							{productlist.length > MAXRESULT?
								<>
									Showing top {MAXRESULT} Product results
								</>
							:
							<>
								{productlist.length} Product{productlist.length > 1?"s":""} found
							</>}
						</div>}
						{colorlist.length > 0 && <div>
							{colorlist.length > MAXRESULT?
								<>
									Showing top {MAXRESULT} Color results
								</>
							:
							<>
								{colorlist.length} Color{colorlist.length > 1?"s":""} found
							</>}
						</div>}
					</div>}
					{productlist.length > 0 && <div className={styles.resultholder}>
						<div className={"text-centered "+styles.resultholderheading}>Products</div>

						<Productlist listproductobj={productlist.slice(0, MAXRESULT)} />
					</div>}
					{colorlist.length > 0 && <div className={styles.resultholder}>
						<div className={"text-centered "+styles.resultholderheading}>Colors</div>
						<Colorlist listobj={colorlist.slice(0, MAXRESULT)} />
					</div>}
					{(productlist.length < 1 && colorlist.length < 1) && <>
						<div className={"text-centered font-size-large "+styles.resulttextonly}>No results found</div>
					</>}
				</>}
			</>}
		</Layout>
	)
}

export default SearchPage
