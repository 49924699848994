import React, {useState, useRef} from "react"
import {Link} from "gatsby"

import WebappImage from "../image/webappimage"
import formatBOYSEN from "../../../lib/formatBoysen"

import * as styles from "./productlist.module.css"


const PAGECOUNT = 12;

const Productlist = ({ listproductobj, linksuffix }) => {
	const [pageidx, setPageidx] = useState(0);


	const listholder = useRef(null);

	const setPaginationidx = function(e, newidx)
	{
		if (e) {
			e.preventDefault();
		}
		setPageidx(newidx);
		setTimeout(function() {
			scrollToListTop();
		}, 100);

	}


	function scrollToListTop()
	{
		const headerelementheight = document.getElementsByName("headersizer")[0].getBoundingClientRect().height;
		let position = listholder.current.getBoundingClientRect();

		// scrolls to just above element
		window.scrollTo({
			behavior: 'smooth',
			top: position.top + window.scrollY - headerelementheight*1.5
		});
	}


	const addlinksuffix = (typeof linksuffix === "undefined"?"":"/"+linksuffix);
	const finallist = listproductobj.slice(pageidx*PAGECOUNT, (pageidx+1)*PAGECOUNT);
	const paginationlist = [...Array(Math.ceil(listproductobj.length/PAGECOUNT)).keys()];

	return (<div ref={listholder}>
		<a name={"productlist"} />
		{
			finallist.map((product, idx)=>{
				// Assumes filtered list
				var productname = "";
				if (product.hasOwnProperty("displayname")) {
					if (product.displayname) {
						productname = product.displayname;
					}
				}
				if (productname.length < 1) {
					productname = product.name;
					if (product.hasOwnProperty("subtitle")) {
						if (product.subtitle) {
							productname = productname + " " + product.subtitle;
						}
					}
				}

				productname = productname.replace("boysen-name","boysen-name futuraboldfont");

				return <Link to={"/products/"+product.url+addlinksuffix} className={styles.itemlink+"  textcolor-primary"}>
					<WebappImage httowdpercent={"141%"} srcurl={product.imageurl} origwd={1745} alt={formatBOYSEN.stripNameTags(productname)} />
					<div className={styles.productname} dangerouslySetInnerHTML={{__html:productname}} />
					<div className={styles.learnmore+" font-size-small"}>Learn More</div>
				</Link>
			})
		}
		{paginationlist.length > 1 ? <div className={styles.pageinationholder}>
			{<button disabled={pageidx===0} onClick={(e)=>{setPaginationidx(e, pageidx-1)}}>&lt;</button>}
			{paginationlist.map((pagenationnum, pagenationnumidx)=>{
				return <button className={pagenationnumidx === pageidx?styles.pageinationactive+" font-style-bold":""} onClick={(e)=>{setPaginationidx(e, pagenationnum)}}>{(pagenationnum+1)}</button>
			})}
			<button disabled={pageidx+1>=paginationlist.length} onClick={(e)=>{setPaginationidx(e, pageidx+1)}}>&gt;</button>

		</div>:<div className={styles.pageinationstub}>&nbsp;</div>}
	</div>)
}

export default Productlist
